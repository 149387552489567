import _config from "../config";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const config_1 = _config;
function getOptions(userOptions) {
  return {
    email: userOptions.email,
    domains: userOptions.domains || config_1.DEFAULT_CONFIG.domains,
    topLevelDomains: userOptions.topLevelDomains || config_1.DEFAULT_CONFIG.topLevelDomains,
    secondLevelDomains: userOptions.secondLevelDomains || config_1.DEFAULT_CONFIG.secondLevelDomains,
    distanceFunction: userOptions.distanceFunction || config_1.DEFAULT_CONFIG.distanceFunction,
    domainThreshold: userOptions.domainThreshold || config_1.DEFAULT_CONFIG.domainThreshold,
    secondLevelThreshold: userOptions.secondLevelThreshold || config_1.DEFAULT_CONFIG.secondLevelThreshold,
    topLevelThreshold: userOptions.topLevelThreshold || config_1.DEFAULT_CONFIG.topLevelThreshold,
    suggested: userOptions.suggested || undefined,
    empty: userOptions.suggested || undefined
  };
}
exports.default = getOptions;
export default exports;