var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
function assert(condition, message) {
  if (!condition) {
    throw new Error(message);
  }
}
exports.default = assert;
export default exports;