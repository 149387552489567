var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const WHITESPACE_PATTERN = /\s/;
/**
 * Just in case a browser doesn't support .trim
 * Copied from https://github.com/Trott/trim
 * Credits to them
 */
function trim(str) {
  if (str.trim) {
    return str.trim();
  }
  return right(left(str));
}
function left(str) {
  if (str.trimLeft) return str.trimLeft();
  return str.replace(/^\s\s*/, "");
}
function right(str) {
  if (str.trimRight) return str.trimRight();
  let i = str.length;
  while (WHITESPACE_PATTERN.test(str.charAt(--i)));
  return str.slice(0, i + 1);
}
exports.default = trim;
export default exports;